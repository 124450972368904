<template>
  <v-container class="text-top">
    <v-row>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="450"
        transition="dialog-top-transition"
      >
        <v-card>
          <v-alert dense type="success">
            {{ dialogHead }}
          </v-alert>
          <v-card-text>
            <div class="my-4 text-subtitle-1">{{ dialogMessage }}</div>
          </v-card-text>
          <v-card-text v-if="dialogDesc">
            <div class="text-center text-subtitle-1">
              고객센터 : 02)979-7412
            </div>
            <div class="text-center">운영시간 : 오전 09:00 ~ 오후 06:00</div>
            <div class="text-center">점심시간 : 12:00 ~ 13:00</div>
            <div class="text-center">토/일/공휴일 휴무</div>
            <div class="text-center">카카오톡 채널 '토마토세븐' 상담가능</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="dialog = false"> 확인 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import EventBus from "./EventBus";

export default {
  data() {
    return {
      dialog: false,
      dialogHead: "",
      dialogMessage: "",
      dialogDesc: false,
      count: 0,
    };
  },
  computed: {
    height1() {
      return this.dialogHead;
    },
  },
  created() {
    EventBus.$on("push-msg", (payload) => {
      this.dialogHead = payload.Head;
      this.dialogMessage = payload.Message;
      this.dialogDesc = payload.Desc;
      this.count++;
      this.dialog = true;
      console.log(this.count);
    });
  },
  methods: {
    init() {
      this.dialog = false;
      this.dialogHead = "";
      this.dialogMessage = "";
      this.dialogDesc = false;
    },
  },
};
</script>
